<template>
    <div class="recipe-details page" v-if="currentRecipe != null">
        <div class="quarter-design-circle"></div>
        <b-container  fluid="xl">
            <b-row>
                <b-col cols="12" md="5" lg="6">
                    <div class="img-share-wrap">
                        <h1 class="only-mobile">{{currentRecipe.name}}</h1>
                        <!-- tags -->
                        <div class="tags-wrap only-mobile" v-if="currentRecipe.recipe_tag">
                            <span class="making-time">
                                <img src='@/assets/images/icons/clock.svg' />
                                {{currentRecipe.making_time}}
                            </span>
                            <span class="dish" v-if="currentRecipe.dish != ''">
                                <img src='@/assets/images/icons/user.svg' />
                                {{currentRecipe.dish}}
                            </span>                       
                            <span v-for="tag in currentRecipe.recipe_tag" :key='"m_" + tag.id'>
                                <img v-if=" tag.image" :src="src_url + tag.image.path + '/' + tag.image.file_name"   />
                                {{tag.name}}
                            </span>                             
                        </div>
                        <!-- no custom tags -->
                        <div class="tags-wrap only-mobile" v-else>                    
                            <span class="making-time">
                                <img src='@/assets/images/icons/clock.svg' />
                                {{currentRecipe.making_time}}
                            </span>
                            <span class="dish" v-if="currentRecipe.dish != ''">
                                <img src='@/assets/images/icons/user.svg' />
                                {{currentRecipe.dish}}
                            </span>                               
                        </div>
                        <img  v-if="currentRecipe.image && itemSrc(currentRecipe, 'w1080h970') != 'no-image' && itemSrcset(currentRecipe) != 'no-image'"
                            sizes="(max-width: 767px) 100vw, 50vw" 
                            :srcset="itemSrcset(currentRecipe)"
                            :src="src_url + '/' + itemSrc(currentRecipe, 'w1080h970')"                 
                            class="w-100 recipe-img"
                        />
                        <!-- noimage -->
                        <img v-else src="@/assets/images/noimages/noimage-recept.jpg" class="w-100 recipe-img"/>
                        <!-- share -->
                        <div class="recipe-details__share only-desctop">
                            <div class="title">Tetszik?</div>
                            <div class="lead">Akkor küldd el másoknak is!</div>
                            <div class="share-buttons">
                                <a :href="'mailto:?subject=Kunsági Éden recept ajánló&body=' + currentUrl" class="btn btn--share"
                                    @click="setDataLayer('recipe_share', 'email', currentRecipe.name)"
                                >
                                    <img src="@/assets/images/icons/ic-share-envelope.svg" />                                    
                                    E-mail
                                </a>
                                <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl" class="btn btn--share"
                                    @click="setDataLayer('recipe_share', 'facebook', currentRecipe.name)"
                                >
                                    <img src="@/assets/images/icons/ic-share-facebook.svg" />
                                    Facebook
                                </a>
                                <button tpe="button" class="btn btn--share" @click="getLinkCopy()">
                                    <img src="@/assets/images/icons/ic-share-link.svg" />
                                    Link másolása
                                </button>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="7" lg="6">
                    <div class="recipe-details__content">
                        <h1 class="only-desctop">{{currentRecipe.name}}</h1>
                        <!-- tags -->
                        <div class="tags-wrap only-desctop" v-if="currentRecipe.recipe_tag">
                            <span class="making-time">
                                <img src='@/assets/images/icons/clock.svg' />
                                {{currentRecipe.making_time}}
                            </span>
                            <span class="dish" v-if="currentRecipe.dish != ''">
                                <img src='@/assets/images/icons/user.svg' />
                                {{currentRecipe.dish}}
                            </span>                       
                            <span v-for="tag in currentRecipe.recipe_tag" :key='tag.id'>
                                <img v-if=" tag.image" :src="src_url + tag.image.path + '/' + tag.image.file_name"   />
                                {{tag.name}}
                            </span>                             
                        </div>
                        <!-- no custom tags -->
                        <div class="tags-wrap only-desctop" v-else>                    
                            <span class="making-time">
                                <img src='@/assets/images/icons/clock.svg' />
                                {{currentRecipe.making_time}}
                            </span>
                            <span class="dish" v-if="currentRecipe.dish != ''">
                                <img src='@/assets/images/icons/user.svg' />
                                {{currentRecipe.dish}}
                            </span>                               
                        </div>
                        <div class="recipe-details__ingredients" v-if="currentRecipe.short_description!= null || currentRecipe.short_description != undefined || currentRecipe.short_description != ''">
                            <h3>Hozzávalók</h3>
                            <div class="short_desc" v-html="currentRecipe.short_description"></div>
                        </div>
                        <div class="recipe-details__description" v-if="currentRecipe.description!= null || currentRecipe.description != undefined || currentRecipe.description != ''">
                            <h3>Elkészítés</h3>
                            <div class="desc" v-html="currentRecipe.description"></div>
                        </div>                        
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div class="more-recipes-wrap">
            <div class="half-left-dc"></div>  
            <b-container  fluid="xl">
                <b-row class="recipe-details__other-recipes other-recipes-block">
                    <b-col cols="12">
                        <div class="title-wrap">
                            <h2>További receptek</h2>
                            <router-link class="btn btn--brown" :to="{name: 'Recipes'}" @click.native="setDataLayer('all_content_click', 'all_recipe_click', '')">További receptek</router-link>
                        </div>
                        
                        <OwlCarousel :items="3" :margin="30" :dots="false"  :loop="false" 
                            :responsive="{ 
                                0:{
                                    items:1,														
                                    margin: 10,
                                    mouseDrag: true,                                 
                                },      													
                                768:{items:2, mouseDrag:true, margin: 30},
                                992:{items:3, mouseDrag:false, margin:20},                            
                                1300:{items:3, mouseDrag:false}                            
                            }">
                                <RecipeItem 
                                    v-for="recipe in otherRecipes" :key="recipe.id"
                                    :recipe="recipe" 
                                />
                        </OwlCarousel>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <ProductsRow />
        <Socialnetwork  />  
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import OwlCarousel from 'vue-owl-carousel'
import RecipeItem from '@/components/pages/RecipeItem.vue'
import ProductsRow from '@/components/layout/ProductsRow'
import Socialnetwork from '@/components/layout/Socialnetwork'

export default {
    mixins: [apiH],   
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME,
            currentRecipe:null,                
            otherRecipes:[]
        }
    },
    components:{
        OwlCarousel,
        RecipeItem,
        ProductsRow,
        Socialnetwork
    },
    computed:{
        currentUrl(){
            return window.location.href
        },
        metaTitle()  {
            return this.currentRecipe.meta_title != '' ? this.currentRecipe.meta_title : this.currentRecipe.name
        }
    },
    created()  {
        this.loadRecipes()
    },
    watch:{
        $route (){
            this.loadRecipes()
        }
    },
    methods:{
        loadRecipes(){
            var _this = this;            

            _this.get('recipe',
                {
                    params: {                        
                        relations: 1,
                        where: [['where','status', '1']],   
                        order_by:[
                            ['created_at','desc']                
                        ],                    
                    }
                },
                function(resp){                                        
                    _this.sortRecipes(resp.data.recipe)
                }, function(err){
                    console.log(err);     
                    _this.currentRecipe = null
                    _this.otherRecipes = []
                }
            )
        },
        sortRecipes(recipelist){
            var _this = this   
            var currentSlug = this.$route.params.recipe_slug                         
            
            this.currentRecipe = recipelist.find( item => currentSlug == item.slug )

             if (this.currentRecipe == null || this.currentRecipe == undefined){
                _this.$router.push({name:'404-page'})                    
            }
            
            var count = 0
            this.otherRecipes = []
            
            recipelist.forEach(element => {                
                if ( _this.currentRecipe != null || _this.currentRecipe != undefined) {
                    if ( element.slug != _this.currentRecipe.slug && count < 9) {
                        _this.otherRecipes.push(element)
                        count += 1
                    }
                }                 
            });

            this.setMetaTags({
                title: 'Kunsági Éden - ' + this.metaTitle, 
                desc: '', 
                keywords: '', 
                ogType: 'website', 
                ogTitle: 'Kunsági Éden -' +  this.metaTitle,
                ogImage: null, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc:''
            })

        },        
        getLinkCopy(){   
            var str = window.location.href;                     
            var el = document.createElement('input');
                        
            el.value = str;            
            
            el.setAttribute('type', 'text');
            el.style.position = 'absolute';
            el.style.left = '-9999px';

            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            
            document.body.removeChild(el);

            this.setDataLayer('recipe_share', 'link', this.currentRecipe.name)
        }
        
    }
}
</script>