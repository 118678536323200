<template>
    <section class="products-row">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12"><h2>Termékek</h2></b-col>
                <b-col cols="12" md="4" v-for="product in allProducts" :key="product.id">                   
                    <router-link class="products-row__item" :to="{path: '/termekeink' + '#' + url_slug(product.name)}" @click.native="setDataLayer('product_link_click', product.name, '')">
                        <img :src="imgurl + '/data/products/' + product.image" class="img-fluid main-img" />
                        <div class="name" v-html="product.nameWrapped"></div>
                        <img 
                            v-if="product.design_img && ( product.design_img != '' ||  product.design_img != null ||  product.design_img != undefined)"
                            :src="imgurl + '/data/products/' + product.design_img" 
                            class="w-100"
                        />
                        <div class="lead">{{product.lead}}</div>
                    </router-link>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    data(){
        return{
            imgurl: process.env.VUE_APP_BASE_URL
        }
    },
   computed:{
       allProducts(){
           return this.$store.state.products
       }
   }
}
</script>